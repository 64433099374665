import React, { Fragment } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import Layout from '../layouts'
import PageWrapper from '../components/PageWrapper'
import { colors } from '../components/theme'
import MetaHeader from '../components/MetaHeader'

const ToSWrapper = styled(PageWrapper)`
  max-width: 800px;

  p {
    line-height: 1.45;
  }
  h3 {
    margin: 2.5rem 0 1rem;
  }
`

const TopLink = styled.a`
  display: block;
  margin-top: 2rem;
  font-weight: bold;
  text-align: center;
`

const Address = styled.div`
  background: #fff;
  padding: 1rem 1.5rem;
  margin: 1rem 0;
  border-radius: 1rem;
  color: ${colors.grey};
`

const Disclaimer = () => (
  <Fragment>
    <p>FEMALE FUNDERS TERMS AND CONDITIONS OF SERVICE</p>

    <p>
      PLEASE NOTE THAT YOUR USE OF AND ACCESS TO OUR SERVICES (DEFINED BELOW)
      ARE SUBJECT TO THE FOLLOWING TERMS AND CONDITIONS OF USE/SERVICE (“
      <b>THE TERMS</b>”); IF YOU DO NOT AGREE TO ALL OF THE FOLLOWING, YOU MAY
      NOT USE OR ACCESS THE SERVICES IN ANY MANNER.
    </p>
    <p>
      <b>Last Updated: October 27, 2015</b>
    </p>
  </Fragment>
)

const TermsOfService = () => (
  <Layout>
    <MetaHeader title="Terms of Service" />

    <ToSWrapper>
      <section id="terms-of-service">
        <h2>Terms and Conditions of Service</h2>
      </section>
      <section>
        <Disclaimer />
        <p>
          Welcome to Female Funders. Please read on to learn the rules and
          restrictions that govern your use of the Female Funders website(s)
          located at{' '}
          <a
            href="https://femalefunders.com"
            target="_blank"
            rel="noopener noreferrer">
            femalefunders.com
          </a>{' '}
          (the <b>“Website”</b>) and the products, services and applications
          made available through Female Funders (the “Services”). If you have
          any questions, comments, or concerns regarding these terms or the
          Services, please contact us at:{' '}
          <a href="mailto:hello@femalefunders.com">hello@femalefunders.com</a>.
        </p>
        <p>
          These Terms are a binding contract between you and Female Funders Inc.
          (“Female Funders,” “we” and “us”). You must agree to and accept all of
          the Terms, or you don’t have the right to use the Services. Your using
          the Services in any way means that you agree to all of these Terms,
          and these Terms will remain in effect while you use the Services.
        </p>
        <h3>About the Service</h3>
        <p>
          Female Funders is a service which empowers women to learn about and
          engage in angel investment.{' '}
          <b>
            You understand that through use of the Services you may be exposed
            to content from a variety of sources, and that Female Funders is not
            responsible for the accuracy, usefulness, safety, or intellectual
            property rights of or relating to such content. In addition
            information provided by Female Funders and its network of partners
            is provided as a service to assist in increasing education and
            knowledge of funding options. Such information is provided for
            education and reference purposes only and the Services are not
            intended to provide professional, legal, tax or financial advice. We
            also do not provide any representation that the information we make
            available will actually improve your ability to invest given the
            complexity of investing and funding of diverse projects with
            different risk profiles. You should consult the services of a
            competent licensed professional when you need any assistance of this
            type. You agree to waive, and hereby do waive, any legal or
            equitable rights or remedies you have or may have against us with
            respect thereto, and agree to indemnify and hold us and our
            affiliates harmless to the fullest extent allowed by law regarding
            all matters related to your use of the Services.
          </b>
        </p>
        <h3>Licensed Content and Purchases</h3>
        <p>
          If you are a registered subscriber of the Services, you will have the
          ability to subscribe to specific content provided by our contributing
          community on angel investing and other related topics (“
          <b>Licensed Content</b>”). You may subscribe to specific Licensed
          Content by purchasing access to the same through the Services using
          the payment portal and checkout process provided. Please note that
          Licensed Content may not be reviewed, assessed or any sources verified
          and accordingly, your use and reliance on such Licensed Content should
          be based on your own judgment. If you have reason to believe that any
          Licensed Content infringes any copyright, please refer to our
          Copyright Policy in Section [4] below for further information on how
          to inform us about it.{' '}
        </p>
        <p>
          You may access Licensed Content, only as permitted under this
          Agreement. Female Funders and its licensors reserve all rights not
          expressly granted in and to the Licensed Content.{' '}
        </p>
        <p>
          You agree to not engage in the use, copying, or distribution of any of
          the Licensed Content other than expressly permitted herein, including
          any use, copying, or distribution of any Licensed Content obtained
          through the Services for any commercial purposes.
        </p>
        <p>
          You agree not to try to circumvent, disable or otherwise interfere
          with security-related features or restrictions built into the systems
          used to provide the Services or specific Licensed Content to prevent
          copying, printing or other uses of any Licensed Content.
        </p>
        <h3>Will These Terms Ever Change?</h3>
        <p>
          We are constantly trying to improve our Services, so these Terms may
          need to change along with the Services. We reserve the right to change
          the Terms and the Services at any time, but if we make any substantive
          changes to these Terms we will endeavour to bring it to your attention
          by placing a notice on the homepage of the Website and/orby sending
          you an email.
        </p>
        <p>
          If you don’t agree with the new Terms, you are free to reject them;
          unfortunately, that means you will no longer be able to use the
          Services. If you use the Services in any way after a change to the
          Terms is effective, that means you agree to all of the changes.
        </p>
        <p>
          Except for changes by us as described here, no other amendment or
          modification of these Terms will be effective unless in writing and
          signed by both you and us.
        </p>
        <p>
          We may suspend or discontinue any part of the Services, or we may
          introduce new features or impose limits on certain features or
          restrict access to parts or all of the Services. We’ll try to give you
          notice when we make a material change to the Services that would
          adversely affect you, but this isn’t always practical. Similarly, we
          reserve the right to remove any Content from the Services at any time,
          for any reason (including, but not limited to, if someone alleges you
          contributed that Content in violation of these Terms), in our sole
          discretion, and without notice.
        </p>
        <h3 id="privacy">What About My Privacy?</h3>
        <p>
          Female Funders takes the privacy of its users very seriously. Our
          Services are aimed at helping females to learn about angel investing
          and funding. However, we join the industry in acknowledging that
          minors need protection and they may not be able to provide valid
          consent accordingly we comply with the requirements of the Children
          Online Privacy Protection Act (“<b>COPPA”</b>). For more information
          about COPPA and our data protection policies, please see our{' '}
          <Link to="/privacy-policy">Privacy Policy</Link>.
        </p>
        <h3>What are the basics of using Female Funders?</h3>
        <p>
          To access and use certain features and content on the Website, You may
          be required to sign up for an account, and select a password and user
          name (“Female Funders User ID”). You promise to provide accurate,
          complete, and updated registration information about yourself. You may
          not select as your Female Funders User ID a name that you don’t have
          the right to use, or another person’s name with the intent to
          impersonate that person. You may not transfer your account to anyone
          else without our prior written permission.
        </p>
        <p>
          You represent and warrant that you are of legal age to form a binding
          contract (or if not, you’ve received your parent’s or guardian’s
          permission to use the Services and gotten your parent or guardian to
          agree to these Terms on your behalf).
        </p>
        <p>
          You will only use the Services for your own personal, non-commercial
          use, and not on behalf of or for the benefit of any third party, and
          only in a manner that complies with all laws that apply to you. If
          your use of the Services is prohibited by applicable laws, then you
          aren’t authorized to use the Services. We can’t and won’t be
          responsible for your using the Services in a way that breaks the law.
        </p>
        <p>
          You will not share your account or password with anyone, and you must
          protect the security of your account and your password. You’re
          responsible for any activity associated with your account.
        </p>
        <p>
          Your use of the Services is subject to the following additional
          restrictions:
        </p>
        <p>
          You represent, warrant, and agree that you will not contribute any
          Content or User Submission (each of those terms is defined below) or
          otherwise use the Services or interact with the Services in a manner
          that:
          <ol>
            <li>
              Infringes or violates the intellectual property rights or any
              other rights of anyone else (including Female Funders);
            </li>
            <li>Violates any law or regulation;</li>
            <li>
              Is harmful, fraudulent, deceptive, threatening, harassing,
              defamatory, obscene, or otherwise objectionable;
            </li>
            <li>
              Jeopardizes the security of your Female Funders account or anyone
              else’s (such as allowing someone else to log in to the Services as
              you);
            </li>
            <li>
              Attempts, in any manner, to obtain the password, account, or other
              security information from any other user;
            </li>
            <li>
              Violates the security of any computer network, or cracks any
              passwords or security encryption codes;
            </li>
            <li>
              Runs Maillist, Listserv, any form of auto-responder or “spam” on
              the Services, or any processes that run or are activated while you
              are not logged into the Services, or that otherwise interfere with
              the proper working of the Services (including by placing an
              unreasonable load on the Services’ infrastructure);
            </li>
            <li>
              “Crawls,” “scrapes,” or “spiders” any page, data, or portion of or
              relating to the Services or Content (through use of manual or
              automated means);
            </li>
            <li>Copies or stores any significant portion of the Content;</li>
            <li>
              Decompiles, reverse engineers, or otherwise attempts to obtain the
              source code or underlying ideas or information of or relating to
              the Services
            </li>
            <li>
              A violation of any of the foregoing is grounds for termination of
              your right to use or access the Services.
            </li>
          </ol>
        </p>
        <p>
          A violation of any of the foregoing is grounds for termination of your
          right to use or access the Services.
        </p>
        <h3>What are my rights on Female Funders?</h3>
        <p>
          The materials displayed or performed or available on or through the
          Services, including, but not limited to, text, graphics, data,
          articles, photos, images, illustrations, User Submissions, and so
          forth (all of the foregoing, the “Content”) are protected by copyright
          and/or other intellectual property laws. You promise to abide by all
          copyright notices, trademark rules, information, and restrictions
          contained in any Content you access through the Services, and you
          won’t use, copy, reproduce, modify, translate, publish, broadcast,
          transmit, distribute, perform, upload, display, license, sell or
          otherwise exploit for any purpose any Content not owned by you, (i)
          without the prior consent of the owner of that Content or (ii) in a
          way that violates someone else’s (including Female Funders’) rights.
        </p>
        <p>
          You understand that Female Funders owns the Services. You won’t
          modify, publish, transmit, participate in the transfer or sale of,
          reproduce (except as expressly provided in this Section), creative
          derivative works based on, or otherwise exploit any of the Services.
        </p>
        <p>
          The Services may allow you to copy or download certain Content; please
          remember that just because this functionality exists, doesn’t mean
          that all the restrictions above don’t apply – they do!
        </p>
        <h3>
          Do I Have to Grant Any Licenses to Female Funders or to Other Users?
        </h3>
        <p>
          Anything you post, upload, share, store, or otherwise provide through
          the Services is your “User Submission.” Some User Submissions are
          viewable by other users. In order to display your User Submissions on
          the Services, and to allow other users to enjoy them (where
          applicable), you grant us certain rights in those User Submissions.
          Please note that all of the following licenses are subject to our
          Privacy Policy to the extent they relate to User Submissions that are
          also your personally-identifiable information.
        </p>
        <p>
          For all User Submissions, you hereby grant Female Funders a license to
          translate, modify (for technical purposes, for example making sure
          your content is viewable on an iPhone as well as a computer) and
          reproduce and otherwise act with respect to such User Submissions, in
          each case to enable us to operate the Services, as described in more
          detail below. This is a license only – your ownership in User
          Submissions is not affected.
        </p>
        <p>
          If you store a User Submission in your own personal Female Funders
          account, in a manner that is not viewable by any other user except you
          (a “Personal User Submission”), you grant Female Funders the license
          above, as well as a license to display, perform, and distribute your
          Personal User Submission for the sole purpose of making that Personal
          User Submission accessible to you and providing the Services necessary
          to do so.
        </p>
        <p>
          If you share a User Submission only in a manner that only certain
          specified users can view (for example, a private message to one or
          more other users)(a “Limited Audience User Submission”), then you
          grant Female Funders the licenses above, as well as a license to
          display, perform, and distribute your Limited Audience User Submission
          for the sole purpose of making that Limited Audience User Submission
          accessible to such other specified users, and providing the Services
          necessary to do so. Also, you grant such other specified users a
          license to access that Limited Audience User Submission, and to use
          and exercise all rights in it, as permitted by the functionality of
          the Services.
        </p>
        <p>
          If you share a User Submission publicly on the Services and/or in a
          manner that more than just you or certain specified users can view, or
          if you provide us (in a direct email or otherwise) with any feedback,
          suggestions, improvements, enhancements, and/or feature requests
          relating to the Services (each of the foregoing, a “Public User
          Submission”), then you grant Female Funders the licenses above, as
          well as a license to display, perform, and distribute your Public User
          Submission for the purpose of making that Public User Submission
          accessible to all Female Funders users and providing the Services
          necessary to do so, as well as all other rights necessary to use and
          exercise all rights in that Public User Submission in connection with
          the Services and/or otherwise in connection with Female Funders’
          business for any purpose, provided that Female Funders will try to
          notify you if it uses your Public User Submission for any reason other
          than displaying it on the Services. Also, you grant all other users of
          the Services a license to access that Public User Submission, and to
          use and exercise all rights in it, as permitted by the functionality
          of the Services.
        </p>
        <p>
          You agree that the licenses you grant are royalty-free, perpetual,
          sublicenseable, irrevocable, and worldwide.
        </p>
        <p>
          Finally, you understand and agree that Female Funders, in performing
          the required technical steps to provide the Services to our users
          (including you), may need to make changes to your User Submissions to
          conform and adapt those User Submissions to the technical requirements
          of connection networks, devices, services, or media, and the foregoing
          licenses include the rights to do so.
        </p>
        <h3>
          What if I see something on the Services that infringes my copyright?
        </h3>
        <p>
          You may have heard of the Digital Millennium Copyright Act (the
          “DMCA”), as it relates to online service providers, like Female
          Funders, being asked to remove material that allegedly violates
          someone’s copyright. We respect others’ intellectual property rights,
          and we reserve the right to delete or disable Content alleged to be
          infringing, and to terminate the accounts of repeat alleged
          infringers; to review our Copyright Dispute Policy and learn how to
          report potentially infringing content, please see below.
        </p>
        <h3>Copyright Dispute Policy</h3>
        <p>
          We do not permit copyright infringing activities and infringement of
          intellectual property rights on and will remove any content on the
          Services if properly notified that such content infringes on another’s
          intellectual property rights.
        </p>
        <p>
          If you are a copyright owner or an agent thereof and believe that any
          content made available through Services infringes upon your copyright,
          you may submit a notification by providing our Copyright Agent with
          the following information in writing:
          <ul>
            <li>
              an electronic or physical signature of the person authorized to
              act on behalf of the owner of the copyright’s interest;
            </li>
            <li>
              a description of the copyrighted work that you claim has been
              infringed, including the URL (i.e., web page address) of the
              location where the copyrighted work exists or a copy of the
              copyrighted work;
            </li>
            <li>
              identification of the URL or other specific location on the Web
              site where the material that you claim is infringing is located;
            </li>
            <li>your address, telephone number, and email address;</li>
            <li>
              a statement by you that you have a good faith belief that the
              disputed use is not authorized by the copyright owner, its agent,
              or the law; and
            </li>
            <li>
              a statement by you, made under penalty of perjury, that the above
              information in your notice is accurate and that you are the
              copyright owner or authorized to act on the copyright owner’s
              behalf.
            </li>
          </ul>
        </p>
        <p>
          You can contact our Copyright Agent via email at{' '}
          <a href="mailto:copyright@femalefunders.com">
            copyright@femalefunders.com
          </a>{' '}
          or by mail at Copyright Agent:
        </p>
        <Address>
          24 Phoebe Street
          <br />
          Toronto, ON
          <br />
          M5T2Z3
        </Address>

        <h3>Who Is Responsible for What I See and Do on the Services?</h3>
        <p>
          Any information or content publicly posted or privately transmitted
          through the Services is the sole responsibility of the person from
          whom such content originated, and you access all such information and
          content at your own risk, and we aren’t liable for any errors or
          omissions in that information or content or for any damages or loss
          you might suffer in connection with it. We cannot control and have no
          duty to take any action regarding how you may interpret and use the
          Content or what actions you may take as a result of having been
          exposed to the Content, and you hereby release us from all liability
          for you having acquired or not acquired Content through the Services.
          We can’t guarantee the identity of any users with whom you interact in
          using the Services and are not responsible for which users gain access
          to the Services.
        </p>
        <p>
          You are responsible for all Content you contribute, in any manner, to
          the Services, and you represent and warrant you have all rights
          necessary to do so, in the manner in which you contribute it. You will
          keep all your registration information accurate and current. You are
          responsible for all your activity in connection with the Services.
        </p>
        <p>
          The Services may contain links or connections to third party websites
          or services that are not owned or controlled by Female Funders. When
          you access third party websites or use third party services, you
          accept that there are risks in doing so, and that Female Funders is
          not responsible for such risks. We encourage you to be aware when you
          leave the Services and to read the terms and conditions and privacy
          policy of each third party website or service that you visit or
          utilize.
        </p>
        <p>
          Female Funders has no control over, and assumes no responsibility for,
          the content, accuracy, privacy policies, or practices of or opinions
          expressed in any third party websites or by any third party that you
          interact with through the Services. In addition, Female Funders will
          not and cannot monitor, verify, censor or edit the content of any
          third party site or service. By using the Services, you release and
          hold us harmless from any and all liability arising from your use of
          any third party website or service.
        </p>
        <p>
          Your interactions with organizations and/or individuals found on or
          through the Services, including payment and delivery of goods or
          services, and any other terms, conditions, warranties or
          representations associated with such dealings, are solely between you
          and such organizations and/or individuals. You should make whatever
          investigation you feel necessary or appropriate before proceeding with
          any online or offline transaction with any of these third parties. You
          agree that Female Funders shall not be responsible or liable for any
          loss or damage of any sort incurred as the result of any such
          dealings.
        </p>
        <p>
          If there is a dispute between participants on this site, or between
          users and any third party, you agree that Female Funders is under no
          obligation to become involved. In the event that you have a dispute
          with one or more other users, you release Female Funders, its
          officers, employees, agents, and successors from claims, demands, and
          damages of every kind or nature, known or unknown, suspected or
          unsuspected, disclosed or undisclosed, arising out of or in any way
          related to such disputes and/or our Services. If you are a California
          resident, you shall and hereby do waive California Civil Code Section
          1542, which says: “A general release does not extend to claims which
          the creditor does not know or suspect to exist in his favor at the
          time of executing the release, which, if known by him must have
          materially affected his settlement with the debtor.”
        </p>

        <h3>What If I Want to Stop Using Female Funders?</h3>
        <p>
          You’re free to do that at any time, by contacting us at{' '}
          <a href="mailto:hello@femalefunders.com">hello@femalefunders.com</a>;
          please refer to our Privacy Policy, as well as the licenses above, to
          understand how we treat information you provide to us after you have
          stopped using our Services.
        </p>
        <p>
          Female Funders is also free to terminate (or suspend access to) your
          use of the Services or your account, for any reason in our discretion,
          including your breach of these Terms. Female Funders has the sole
          right to decide whether you are in violation of any of the
          restrictions set forth in these Terms.
        </p>
        <p>
          Account termination may result in destruction of any Content
          associated with your account, so keep that in mind before you decide
          to terminate your account.
        </p>
        <p>
          Provisions that, by their nature, should survive termination of these
          Terms shall survive termination. By way of example, all of the
          following will survive termination: any obligation you have to pay us
          or indemnify us, any limitations on our liability, any terms regarding
          ownership or intellectual property rights, and terms regarding
          disputes between us.
        </p>

        <h3>What Else Do I Need to Know?</h3>

        <p>
          <em>Warranty Disclaimer.</em> Female Funders does not make any
          representations or warranties concerning any content contained in or
          accessed through the Services, and we will not be responsible or
          liable for the accuracy, copyright compliance, legality, or decency of
          material contained in or accessed through the Services. We make no
          representations or warranties regarding suggestions or recommendations
          of services or products offered or purchased through the Services.
          Products and services purchased or offered (whether or not following
          such recommendations and suggestions) through the Services are
          provided “AS IS” and without any warranty of any kind from Female
          Funders or others (unless, with respect to such others only, provided
          expressly and unambiguously in writing by a designated third party for
          a specific product). THE SERVICES AND CONTENT ARE PROVIDED ON AN
          “AS-IS” BASIS, WITHOUT WARRANTIES OR ANY KIND, EITHER EXPRESS OR
          IMPLIED, INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OF
          MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT,
          OR THAT USE OF THE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE. SOME
          STATES DO NOT ALLOW LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY LASTS,
          SO THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.
        </p>

        <p>
          <em>Limitation of Liability.</em> TO THE FULLEST EXTENT ALLOWED BY
          APPLICABLE LAW, UNDER NO CIRCUMSTANCES AND UNDER NO LEGAL THEORY
          (INCLUDING, WITHOUT LIMITATION, TORT, CONTRACT, STRICT LIABILITY, OR
          OTHERWISE) SHALL COMPANY BE LIABLE TO YOU OR TO ANY OTHER PERSON FOR
          (A) ANY INDIRECT, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGES OF ANY
          KIND, INCLUDING DAMAGES FOR LOST PROFITS, LOSS OF GOODWILL, WORK
          STOPPAGE, ACCURACY OF RESULTS, OR COMPUTER FAILURE OR MALFUNCTION, OR
          (B) ANY AMOUNT, IN THE AGGREGATE, IN EXCESS OF THE GREATER OF (I) $100
          OR (II) THE AMOUNTS PAID BY YOU TO COMPANY IN CONNECTION WITH THE
          SERVICES IN THE TWELVE (12) MONTH PERIOD PRECEDING THIS APPLICABLE
          CLAIM, OR (III) ANY MATTER BEYOND OUR REASONABLE CONTROL. SOME STATES
          DO NOT ALLOW THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES, SO THE
          ABOVE LIMITATION AND EXCLUSIONS MAY NOT APPLY TO YOU.
        </p>

        <p>
          <em>Indemnity.</em> You agree to indemnify and hold Female Funders,
          its affiliates, officers, agents, employees, and partners harmless
          from and against any and all claims, liabilities, damages (actual and
          consequential), losses and expenses (including attorneys’ fees)
          arising from or in any way related to any third party claims relating
          to (a) your use of the Services (including any actions taken by a
          third party using your account), and (b) your violation of these
          Terms. In the event of such a claim, suit, or action (“Claim”), we
          will attempt to provide notice of the Claim to the contact information
          we have for your account (provided that failure to deliver such notice
          shall not eliminate or reduce your indemnification obligations
          hereunder).
        </p>

        <p>
          <em>Assignment.</em> You may not assign, delegate or transfer these
          Terms or your rights or obligations hereunder, or your Services
          account, in any way (by operation of law or otherwise) without Female
          Funders’s prior written consent. We may transfer, assign, or delegate
          these Terms and our rights and obligations without consent.
        </p>

        <p>
          <em>Choice of Law.</em> This Agreement shall be governed by the laws
          of the Province of Ontario, Canada. No choice of laws rules of any
          jurisdiction shall apply to this Agreement. You shall only be entitled
          to bring any action or proceeding arising out of or relating to this
          Agreement in a court in Toronto, Ontario, Canada, and You consent to
          the jurisdiction of such courts for any such action or proceeding. You
          waive all rights that You may have or that may hereafter arise to
          contest such jurisdiction of such courts for any action or proceeding
          brought by You. You hereby waive any right You may have to request a
          jury trial with respect to any action brought by You in connection
          with this Agreement.
        </p>

        <p>
          <em>Miscellaneous.</em> You will be responsible for paying,
          withholding, filing, and reporting all taxes, duties, and other
          governmental assessments associated with your activity in connection
          with the Services, provided that the Female Funders may, in its sole
          discretion, do any of the foregoing on your behalf or for itself as it
          sees fit. The failure of either you or us to exercise, in any way, any
          right herein shall not be deemed a waiver of any further rights
          hereunder. If any provision of these Terms is found to be
          unenforceable or invalid, that provision will be limited or
          eliminated, to the minimum extent necessary, so that these Terms shall
          otherwise remain in full force and effect and enforceable. You and
          Female Funders agree that these Terms are the complete and exclusive
          statement of the mutual understanding between you and Female Funders,
          and that it supersedes and cancels all previous written and oral
          agreements, communications and other understandings relating to the
          subject matter of these Terms, and that all modifications to these
          Terms must be in a writing signed by both parties (except as otherwise
          provided herein). No agency, partnership, joint venture, or employment
          is created as a result of these Terms and you do not have any
          authority of any kind to bind Female Funders in any respect
          whatsoever. You and Female Funders agree there are no third party
          beneficiaries intended under these Terms.
        </p>

        <p>
          <em>Contact.</em> If you have any questions about these Terms or if
          you wish to make any complaint or claim with respect to the Services,
          please contact us at:{' '}
          <a href="mailto:hello@femalefunders.com">hello@femalefunders.com</a>.
        </p>

        <hr />

        <TopLink href="#terms-of-service">Back to the top.</TopLink>
      </section>
    </ToSWrapper>
  </Layout>
)

export default TermsOfService
